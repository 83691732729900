import styled from 'styled-components';

const Chip = styled.button`
  background-color: ${(props) => (props.isActive ? props.color : '#000')};
  opacity: ${(props) => (props.isActive ? 1 : 0.45)};
  color: #fff;
  border: none;
  border-radius: 0;
  cursor: pointer;
  font-size: 0.75rem;
  font-weight: bold;
  padding: 5px 10px;

  &:hover {
    background-color: #000 !important;
  }

  input:checked + & {
    opacity: 1;
  }
`;

export default Chip;
