import React from 'react';
import styled from 'styled-components';

const Heading3 = styled.h2`
  margin: 0;
  transition: opacity 0.25s ease-in-out;
`;

const CardTitle = (props) => {
  const { title } = props;

  return <Heading3 className="o-heading-h3 o-heading-lead">{title}</Heading3>;
};

export default CardTitle;
