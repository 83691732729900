import StoryList from './StoryList';
import { BrowserRouter as Router } from 'react-router-dom';

function App() {
  return (
    <Router>
      <StoryList />
    </Router>
  );
}

export default App;
