import styled from 'styled-components';

const StoriesGrid = styled.section`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 6rem 3rem;

  @media (min-width: 1681px) {
    > :nth-child(1),
    > :nth-child(6),
    > :nth-child(7) {
      grid-column: span 2;
    }
  }

  @media (min-width: 900px) and (max-width: 1600px) {
    grid-template-columns: repeat(3, 1fr);
    gap: 3rem;

    > :nth-child(1),
    > :nth-child(4),
    > :nth-child(5) {
      grid-column: span 2;
    }
  }

  @media (min-width: 580px) and (max-width: 899px) {
    grid-template-columns: repeat(2, 1fr);
    gap: 3rem;

    > :nth-child(1) {
      grid-column: span 2;
    }
  }

  @media (max-width: 579px) {
    grid-template-columns: 1fr;
    gap: 3rem;
  }

  @media (min-width: 580px) {
    
  }

`;

export default StoriesGrid;
