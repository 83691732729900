// ES6 module syntax
import LocalizedStrings from 'react-localization';

const strings = new LocalizedStrings({
    en: {
        next: "Next",
        prev: "Previous",
        topics: "Topics",
        content_types: "Content Types",
        language: "Language",
        all_topics: "All Topics",
        all_types: "All Types",
        noresults: "No results…"
    },
    de: {
        next: "Weiter",
        prev: "Themen",
        topics: "Themen",
        content_types: "Formate",
        language: "Sprache",
        all_topics: "Alle Themen",
        all_types: "Alle Formate",
        noresults: "No results…"
    },
    es: {
        next: "Siguiente",
        prev: "Previo",
        topics: "Temas",
        content_types: "Tipo de Contenido",
        language: "Idioma",
        all_topics: "Todos los Temas",
        all_types: "Todo el Contenido",
        noresults: "No results…"
    },
    zh: {
        next: "下一篇",
        prev: "上一篇",
        topics: "话题",
        content_types: "内容类型",
        language: "语言",
        all_topics: "所有话题",
        all_types: "全部类型",
        noresults: "No results…"
    },
    ja: {
        next: "次頁",
        prev: "前頁",
        topics: "トピック",
        content_types: "コンテントタイプ",
        language: "言語",
        all_topics: "全トピック",
        all_types: "全タイプ",
        noresults: "No results…"
    }
});

export default strings