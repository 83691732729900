import React from 'react';
import FilterGrid from './styled/FilterGrid';
import FilterLabel from './styled/FilterLabel';
import Topics from './Topics';
import Types from './Types';
import strings from '../translations/strings'

const Filters = (props) => {
  const { updateFilters, topic, type } = props;

  return (
    <FilterGrid>
      <FilterLabel>{strings.topics}</FilterLabel>
      <Topics updateFilters={updateFilters} currentTopic={topic} />

      <FilterLabel>{strings.content_types}</FilterLabel>
      <Types updateFilters={updateFilters} currentType={type} />

      {/* <FilterLabel>{strings.language}</FilterLabel>
      <div>
        <Languages updateFilters={updateFilters} currentLang={lang} />
      </div> */}
    </FilterGrid>
  );
};

export default Filters;
