import React, { useState } from 'react';
import styled from 'styled-components';

const Image = styled.img`
  background-color: #f9f9f9;
  display: block;
  height: 278px;
  width: 100%;
  object-fit: cover;
  margin-bottom: 1.25rem;
  opacity: ${(props) => (props.loaded ? 1 : 0)};
  transition: opacity 0.2s ease-in;
`;

const CardImage = (props) => {
  const [isLoaded, setIsLoaded] = useState(false);
  const { src } = props;

  return (
    <Image
      alt=""
      aria-hidden="true"
      src={src}
      loaded={isLoaded}
      onLoad={() => setIsLoaded(true)}
    />
  );
};

export default CardImage;
