import React from 'react';
import styled from 'styled-components';

const List = styled.ul`
  list-style-type: none;
  padding-left: 0;
  display: flex;
  flex-wrap: wrap;
  font-size: 0.75rem;
  font-weight: 700;
  gap: 1ch;

  > li {
    display: flex;
    align-items: center;
    gap: 1ch;

    &:not(:first-child) {
      &::before {
        content: '';
        background-color: currentColor;
        height: 1em;
        width: 1px;
      }
    }
  }
`;

const CardMeta = (props) => {
  const { type,date, readTime, ...rest } = props;

  return (
    <List {...rest}>
      <li>{type}</li>
      {date && <li>{date}</li>}
      {readTime && <li>{readTime}</li>}
    </List>
  );
};

export default CardMeta;
