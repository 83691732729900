import { useCallback, useEffect, useRef, useState } from 'react';
import Paginate from './components/styled/Paginate';
import StoryCard from './StoryCard';
import Filters from './components/Filters';
import TopicsData from './components/TopicsData';
import LanguagesData from './components/LanguagesData';
import TypesData from './components/TypesData';
import StoriesGrid from './components/styled/StoriesGrid';
import PlaceholderCard from './components/styled/PlaceholderCard';
import NoResults from './components/styled/NoResults';
import { useHistory } from 'react-router-dom';
import strings from './translations/strings';

const pageSize = window.pageSize ?? 6;

const StoryList = () => {
  const useQuery = () => new URLSearchParams(window.location.search);
  const query = useQuery();
  const qsTopic = query.get('topic');
  const qsType = query.get('type');
  const qsLang = query.get('lang');
  const qsPage = query.get('page');

  const gridRef = useRef();

  strings.setLanguage(window.StartLang ?? 'en');

  const [topic, setTopic] = useState(
    TopicsData.filter((t) => t.slug === qsTopic).length
      ? TopicsData.filter((t) => t.slug === qsTopic)[0].id
      : 'all'
  );
  const [type, setType] = useState(
    TypesData.filter((t) => t.slug === qsType).length
      ? TypesData.filter((t) => t.slug === qsType)[0].id
      : 'all'
  );

  const [lang, setLang] = useState(
    LanguagesData.filter((t) => t.slug === qsLang).length
      ? LanguagesData.filter((t) => t.slug === qsLang)[0].slug
      : window.StartLang ?? 'en'
  );
  const [stories, setStories] = useState('');
  const [allItems, setAllItems] = useState(0);
  const [currentPage, setCurrentPage] = useState(qsPage || 1);
  const [isBusy, setIsBusy] = useState(true);

  const history = useHistory();

  const fetchStories = useCallback(async () => {
    try {
      setIsBusy(true);
      //console.log("fetchStories", type, topic, lang);
      const baseUrl = window.location.protocol + '//' + window.location.host;
      const url = `${baseUrl}/magna-api/GetStories/${lang}/${type}/${topic}/?page=${currentPage}&startlang=${window.StartLang}`;
      const response = await fetch(url, {
        method: 'GET', // *GET, POST, PUT, DELETE, etc.
        mode: 'cors', // no-cors, *cors, same-origin
      });
      //console.log(response);
      const json = await response.json();
      setStories(json.StoryList);
      setAllItems(json.AllItems);
      setIsBusy(false);

      history.replace({
        search: `topic=${
          TopicsData.filter((t) => t.id === topic)[0]?.slug || 'all'
        }&type=${
          TypesData.filter((t) => t.id === type)[0]?.slug || 'all'
        }&lang=${lang}&page=${currentPage}`,
      });
    } catch (error) {
      console.log('error', error);
    }
  }, [type, topic, lang, currentPage, history]);

  useEffect(() => {
    fetchStories(type, topic, lang, currentPage);
  }, [type, topic, lang, currentPage, fetchStories]);

  // const fetchStories = async (type, topic, lang, page) => {

  const updateFilters = (kind, value) => {
    // const { id, kind } = e.target.dataset;
    // const { value } = e.target;
    setCurrentPage(1);
    // console.log(id,kind)
    switch (kind) {
      case 'type':
        setType(value);
        break;
      case 'topic':
        setTopic(value);
        break;
      case 'lang':
        setLang(value);
        break;
      default:
    }
  };

  const scrollIntoViewWithOffset = (selector, offset) => {
    window.scrollTo({
      behavior: 'smooth',
      top:
        selector?.getBoundingClientRect().top -
        document.body.getBoundingClientRect().top -
        offset,
    });
  };

  const onPagination = (page) => {
    const { selected } = page;

    scrollIntoViewWithOffset(gridRef.current, 120);

    // Need to increment selected by 1 as it uses a 0-based index
    // (i.e. the first page is 0).
    setCurrentPage(selected + 1);
  };

  return (
    <div ref={gridRef}>
      <Filters
        updateFilters={updateFilters}
        topic={topic}
        lang={lang}
        type={type}
      />

      {stories.length > 1 ? (
        <>
          <StoriesGrid className="spacing-top-80 xs:spacing-top-60">
            {isBusy && (
              <>
                {Array.from(Array(pageSize).keys()).map((_, index) => (
                  <PlaceholderCard key={index} />
                ))}
              </>
            )}
            {!isBusy &&
              stories.map((story) => (
                <StoryCard key={story.Id} story={story} />
              ))}
          </StoriesGrid>
          <Paginate
            nextLabel={strings.next}
            previousLabel={strings.prev}
            className="spacing-top-100 xs:spacing-top-50"
            breakLabel="&hellip;"
            pageCount={Math.ceil(allItems / pageSize)}
            forcePage={currentPage - 1}
            onPageChange={onPagination}
            renderOnZeroPageCount={null}
          />
        </>
      ) : (
        <NoResults className="spacing-top-80 xs:spacing-top-60">
          {strings.noresults}
        </NoResults>
      )}
    </div>
  );
};

export default StoryList;
