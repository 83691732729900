import React from 'react';
import TopicsItem from './TopicsItem';
import TopicsData from './TopicsData';
import List from './styled/TopicList';
import Dropdown from './styled/Dropdown';
import { BrowserView, MobileView } from 'react-device-detect';

import strings from '../translations/strings'

const Topics = (props) => {
  const { updateFilters, currentTopic } = props;

  return (
    <>
      {TopicsData && (
        <>
          <BrowserView>
            <List className='topics-filter'>
              <li>
                <TopicsItem
                  id={'all'}
                  slug={'all'}
                  onClick={(e) => updateFilters('topic', 'all')}
                  label= {strings.all_topics}
                  isActive={currentTopic === 'all'}
                />
              </li>
              {TopicsData.filter((topic) => topic.active).map((topic) => {
                const { id, label, slug } = topic;

                return (
                  <li key={id}>
                    <TopicsItem
                      id={id}
                      slug={slug}
                      onClick={(e) => updateFilters('topic', id)}
                      label={label}
                      isActive={currentTopic === id || currentTopic === 'all'}
                    />
                  </li>
                );
              })}
            </List>
          </BrowserView>
          <MobileView>
            <Dropdown
              className="o-input__select"
              style={{ display: 'inline-flex', width: '100%' }}
            >
              <select
                value={currentTopic}
                onChange={(e) => updateFilters('topic', e.target.value)}
              >
                <option key={'all'} value={'all'}>
                  {strings.all_topics}
                </option>
                {TopicsData.filter((topic) => topic.active).map((topic) => {
                  const { id, label } = topic;

                  return (
                    <option key={id} value={id}>
                      {label}
                    </option>
                  );
                })}
              </select>
            </Dropdown>
          </MobileView>
        </>
      )}
    </>
  );
};

export default Topics;
