import React from 'react';
import styled, { keyframes } from 'styled-components';
import CardImage from './components/CardImage';
import CardTitle from './components/CardTitle';
import CardMeta from './components/CardMeta';
import CTACard from './components/CTACard';

const fadeIn = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

const Card = styled.article`
  animation: ${fadeIn} 0.5s ease-in-out forwards;
  cursor: pointer;

  &:hover h2 {
    opacity: 0.5;
  }
`;

const StoryCard = (props) => {
  const { Title, Type, Url, Image, TileSize, ReadTime, Date, IsCTA } = props.story;
  const fixUrl = Url?.substring(1, Url.length);

  const handleOnClick = (url) => {
    window.open(url, '_self');
  };

  if (IsCTA) {
    return <CTACard title={Title} />;
  } else {
    return (
      <Card
        className={TileSize}
        style={{ margin: 0 }}
        onClick={() => handleOnClick(fixUrl)}
        tabIndex={0}
      >
        <CardImage src={Image} />
        <CardTitle title={Title} />
        <CardMeta
          type={Type}
          readTime={ReadTime}
          date={Date}
          className="spacing-top-30 xs:spacing-top-10"
        />
      </Card>
    );
  }
};

export default StoryCard;
