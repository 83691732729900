const TopicsData = window.Topics ?? [
  {
    slug: 'social-responsibility',
    label: 'Social Responsibility',
    id: '07ebcadf-12dd-410b-866b-a0fa78225f98',
  },
  {
    slug: 'sustainability',
    label: 'Sustainability',
    id: '3d4c4c5d-3b98-4577-9a3e-b5410d76e215',
  },
  {
    slug: 'growth',
    label: 'Growth',
    id: 'b62abe17-5d89-49b2-ac5b-13da4bbdb282',
  },
  {
    slug: 'product-technology',
    label: 'Product & Technology',
    id: '584ca6f4-f50e-4af4-8a9b-54fba8229c0b',
  },
  {
    slug: 'diversity-inclusion',
    label: 'Diversity & Inclusion',
    id: 'f16ff7aa-1357-467e-987b-fae1859d2730',
  },
  {
    slug: 'magna-people',
    label: 'Magna People',
    id: 'c1d43145-b632-43de-b61e-4f0384621671',
  },
];

export default TopicsData;
