import styled from 'styled-components';

const FilterLabel = styled.h3`
  font-size: var(--heading-body2-size);
  font-weight: 700;
  text-transform: uppercase;

  @media (max-width: 37.4375em) {
      &:not(:first-child) {
          margin-top: 1.25rem;
      }
  }
`;

export default FilterLabel;
