import React from 'react';
import styled from 'styled-components';

const Card = styled.div`
  margin: 0;
`;

const Image = styled.div`
  background-color: #f2f2f2;
  height: 278px;
  width: 100%;
  margin-bottom: 1.25rem;
`;

const Headline = styled.div`
  background-color: #f7f7f7;
  height: calc(1em * var(--heading-h3-leading) * ${(props) => props.numLines});
  margin: 0;
`;

const Meta = styled.div`
  background-color: #fcfcfc;
  height: calc(0.75rem * 1.4);
`;

const PlaceholderCard = () => {
  return (
    <Card>
      <Image />
      <Headline
        className="o-heading-h3"
        numLines={Math.floor(Math.random() * 3 + 1)}
      />
      <Meta className="spacing-top-30" />
    </Card>
  );
};

export default PlaceholderCard;
