import React from 'react';
import styled from 'styled-components';

const CTA = styled.div`
  margin: 0;
  background-color: #000;
  color: #fff;
  padding: 4.375rem 2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;

  @media (max-width: 599px) {
    padding: 3.125rem 2.5rem;
  }
`;

const CTACard = (props) => {
  const { title } = props;
  return <CTA dangerouslySetInnerHTML={createMarkup(JSON.parse(title).cta)}  />;
};


function createMarkup(html) {
  return {__html: html};
}


export default CTACard;