import styled from 'styled-components';
import ChevronDown from '../icons/ChevronDown';
import { renderToStaticMarkup } from 'react-dom/server';

const Dropdown = styled.div`
  > select {
    height: 2rem;
    padding: 0 2.625rem 0 1rem;
  }

  &::after {
    background: url('data:image/svg+xml, ${encodeURIComponent(
      renderToStaticMarkup(<ChevronDown />)
    )}') no-repeat 50%;
    width: 0.625rem;
    height: auto;
    aspect-ratio: 10/6;
    transform: none;
  }
`;

export default Dropdown;
