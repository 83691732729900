import styled from 'styled-components';
import ReactPaginate from 'react-paginate';

const Paginate = styled(ReactPaginate)`
  --link-color: currentColor;
  --link-underline: none;
  background-color: #000;
  color: #fff;
  list-style-type: none;
  padding: 0;
  display: flex;
  align-items: center;
  gap: 1.25rem;
  padding: 0.625rem 1.25rem;

  li:not(.previous):not(.next) a {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 2em;
    height: 2em;
  }

  .previous {
    margin-right: auto;
    flex: 1;
  }

  .next {
    margin-left: auto;
    text-align: right;
    flex: 1;
  }

  [role='button']:not([aria-disabled='true']) {
    cursor: pointer;
  }

  .selected {
    a {
      background-color: #da291c;
    }
  }

  @media (max-width: 37.4375em) {
      li:not(.previous):not(.next):not(.selected) {
          display: none;
      }
  }
`;

export default Paginate;
