import styled from 'styled-components';

const TypeButton = styled.button`
  background-color: transparent;
  border: none;
  border-radius: 0;
  padding: 0;
  min-height: 0;
  color: #000;
  font-size: var(--heading-small-size);
  cursor: pointer;
  opacity: 0.5;
  font-weight: ${(props) => (props.isActive ? 700 : 400)};

  &:hover {
    background-color: transparent !important;
    opacity: 1;
  }
`;

export default TypeButton;
