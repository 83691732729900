const LanguagesData = window.Langs ?? [
  {
    slug: 'ru',
    label: 'Russian',
    id: '56b76408-d3ec-42e3-b459-9a42b7cce235',
  },
  {
    slug: 'fr',
    label: 'French',
    id: '2581660c-fe40-4b61-87eb-2e2fbb803980',
  },
  {
    slug: 'sb',
    label: 'Serbian',
    id: '851c8a9a-1896-43c7-8ac5-ff5da21bf62d',
  },
  {
    slug: 'sk',
    label: 'Slovak',
    id: 'ef786e11-5b3b-4204-8667-9ca12844ac0e',
  },
  {
    slug: 'cs',
    label: 'Czech',
    id: 'c05c09e9-1fb7-4aba-badf-10e55f54fc39',
  },
  {
    slug: 'pt',
    label: 'Portuguese',
    id: '1626489b-a9a1-44bf-b00d-ddce44851892',
  },
  {
    slug: 'pl',
    label: 'Polish',
    id: 'f8947a0e-e7ba-40a7-a764-709e56244eb3',
  },
  {
    slug: 'it',
    label: 'Italian',
    id: '151b995f-159f-4f15-9573-9f1bc4ad52f5',
  },
  {
    slug: 'es',
    label: 'Spanish',
    id: '573febc2-d614-4ae5-9997-e9140bbec4e6',
  },
  {
    slug: 'ja',
    label: 'Japanese',
    id: 'f466d641-8e32-401b-a731-126a78fca657',
  },
  {
    slug: 'ch',
    label: 'Chinese',
    id: '5138ac71-6374-474c-9d2d-a7b053bb32e3',
  },
  {
    slug: 'de',
    label: 'German',
    id: '3035c650-ed68-4f75-b528-2b7ea2c8e0db',
  },
  {
    slug: 'en',
    label: 'English',
    id: 'f4cda686-1d35-4d77-8f0f-8315440acc5d',
  },
];

export default LanguagesData;
