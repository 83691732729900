import styled from 'styled-components';

const TypeList = styled.ul`
  list-style-type: none;
  padding-left: 0;
  display: flex;
  flex-wrap: wrap;
  gap: 0.625rem 1.875rem;
`;

export default TypeList;
