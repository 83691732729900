import React from 'react';
import Chip from './styled/Chip';

const TOPIC_COLORS = {
  'all': '#000',
  'social-responsibility': '#C86F00',
  sustainability: '#B7BF10',
  growth: '#9B945F',
  'product-technology': '#073D8D',
  'diversity-inclusion': '#4298B5',
  'magna-people': '#DA291C',
  'esg': '#B7BF10',
  'financials': '#4298B5',
  'inside-automotive': '#C86F00',
};

const TopicsItem = (props) => {
  const { slug, onClick, label, isActive } = props;

  return (
    <>
      <Chip
        onClick={onClick}
        color={TOPIC_COLORS[slug]}
        isActive={isActive}
        type="button"
      >
        {label}
      </Chip>
    </>
  );
};

export default TopicsItem;
