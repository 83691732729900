const TypesData = window.Types ?? [
  {
    slug: 'whitepaper',
    label: 'Whitepaper',
    id: '74d29cf7-59eb-49ab-ab4c-83720712251e',
  },
  {
    slug: 'webinar',
    label: 'Webinar',
    id: 'ca9a0752-c08f-430c-b64c-ab90ba790146',
  },
  {
    slug: 'blog',
    label: 'Blog',
    id: '44b346fd-c40e-4e32-84ed-acbf61d104cc',
  },
  {
    slug: 'article',
    label: 'Article',
    id: '5ae9f694-6519-4d07-928d-561b63ea4138',
  },
  {
    slug: 'news-press-release',
    label: 'News & Press Release',
    id: 'fc6304b1-a945-47bf-8445-b093dcf51ec5',
  },
];

export default TypesData;
