import React from 'react';
import TypesData from './TypesData';
import TypeList from './styled/TypeList';
import TypeButton from './styled/TypeButton';
import Dropdown from './styled/Dropdown';
import { BrowserView, MobileView } from 'react-device-detect';
import strings from '../translations/strings'

const Types = (props) => {
  const { updateFilters, currentType } = props;

  return (
    <>
      {TypesData && (
        <>
          <BrowserView>
            <TypeList className='types-filter'>
              <li style={{ marginTop: 0 }}>
                <TypeButton
                  onClick={() => updateFilters('type', 'all')}
                  isActive={currentType === 'all'}
                >
                  {strings.all_types}
                </TypeButton>
              </li>
              {TypesData.filter((type) => type.active).map((type) => {
                const { id, label } = type;

                return (
                  <li key={type.id} style={{ marginTop: 0 }}>
                    <TypeButton
                      onClick={() => updateFilters('type', id)}
                      isActive={currentType === id || currentType === 'all'}
                    >
                      {label}
                    </TypeButton>
                  </li>
                );
              })}
            </TypeList>
          </BrowserView>
          <MobileView>
            <Dropdown
              className="o-input__select"
              style={{ display: 'inline-flex', width: '100%' }}
            >
              <select
                value={currentType}
                onChange={(e) => updateFilters('type', e.target.value)}
              >
                <option key={'all'} value={'all'}>
                {strings.all_types}
                </option>
                {TypesData.filter((type) => type.active).map((type) => {
                  const { id, label } = type;

                  return (
                    <option key={id} value={id}>
                      {label}
                    </option>
                  );
                })}
              </select>
            </Dropdown>
          </MobileView>
        </>
      )}
    </>
  );
};

export default Types;
