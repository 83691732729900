import styled from 'styled-components';

const FilterGrid = styled.div`
  display: grid;
  grid-template-columns: max-content 1fr;
  gap: 1.25rem 2rem;
  align-items: center;

  > * {
    margin-top: 0;
  }

  @media (max-width: 599px) {
    grid-template-columns: 1fr;
    gap: 0.625rem 1.25rem;
  }
`;

export default FilterGrid;
