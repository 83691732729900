import React from 'react';

const ChevronDown = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="10.391"
    height="7.556"
    viewBox="0 0 10.391 7.556"
  >
    <path
      d="M0,0,5.867,4.792,0,9.211"
      transform="translate(9.81 0.474) rotate(90)"
      fill="none"
      stroke="#000"
      strokeWidth="1.5"
    />
  </svg>
);

export default ChevronDown;
